<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <OrderFilter
          :filterLabels="filterLabels"
          @switchFilter="switchFilter"
        />
        <div>
          <span class="h1 text-primary font-large-1">
            Total Order: {{ this.rows.length }}
          </span>
        </div>
        <div
          class="d-flex flex-row-reverse justify-content-between align-items-center"
        >
          <div class="my-1" v-if="filter !== 'Failed Payment'">
            <b-form-group>
              <flat-pickr
                v-model="dateFilter"
                :config="{
                  dateFormat: 'Y-m-d',
                  defaultDate: 'today',
                }"
                class="form-control font-small-2 w-auto"
                placeholder="Choose Date"
                readonly="readonly"
                @input="switchFilter()"
              />
            </b-form-group>
          </div>
        </div>
        <b-card>
          <div>
            <b-button
              @click="ExportToExcel()"
              variant="outline-primary"
              class="btn-tour-skip mt-1"
            >
              <span class="mr-25 align-middle">Extract Orders</span>
            </b-button>
          </div>
          <MyTable
            :columns="columns"
            :rows="rows"
            :isLoading="isLoading"
            :checkboxEnabled="true"
            @switchFilter="switchFilter"
          >
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="goToOrderSummary(props.row.id)"
                >
                  {{ props.row.id }}
                </b-button>
              </span>
              <div
                v-if="props.column.field === 'order_type'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{ props.row.order_type }}
                </b>
              </div>

              <div
                v-if="props.column.field === 'pickup'"
                class="d-flex justify-content-center"
              >
                <b v-if="props.row.pickup">
                  {{ props.row.pickup.area }}
                </b>
                <b v-else> N/A </b>
              </div>

              <div
                v-if="props.column.field === 'recipient.address_postcode'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{ props.row.recipient.address_postcode }}
                </b>
              </div>

              <div
                v-if="props.column.field === 'order_placed_date'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{
                    !props.row.order_placed_date
                      ? ""
                      : props.row.order_placed_date.substr(0, 10)
                  }}
                </b>
              </div>

              <div
                v-if="props.column.field === 'delivery_date'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{
                    !props.row.delivery_date
                      ? ""
                      : props.row.delivery_date.substr(0, 10)
                  }}
                </b>
              </div>

              <div
                v-if="props.column.field === 'quantity'"
                class="h-100 d-flex justify-content-center align-items-center"
              >
                <b>
                  {{ props.row.quantity }}
                </b>
              </div>

              <span v-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status === 'Active'"
                  block
                  variant="light-purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Assigned'"
                  block
                  variant="dark-purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'In Warehouse'"
                  block
                  variant="light-blue"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'In Transit'"
                  block
                  variant="yellow"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Out For Delivery'"
                  block
                  variant="purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Delivered'"
                  block
                  variant="turquoise"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Close Store'"
                  block
                  variant="maroon"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button v-else block variant="orange">
                  {{ props.row.status }}
                </b-button>
              </span>

              <div
                v-if="props.column.field === 'action'"
                class="d-flex justify-content-center"
              >
                <div v-if="filter === 'Failed Payment'">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="payOrder(props.row)">
                      <span>Pay Now</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        cancelOrder(props.row.id, props.row.originalIndex)
                      "
                    >
                      <span>Delete Order</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div v-else>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      @click="generateLabel(props.row, props.row.originalIndex)"
                    >
                      <span>Generate Label</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        updateOrderStatus(props.row.id, props.row.originalIndex)
                      "
                    >
                      <span>Update Status</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="`/track-and-trace/order-tracking/${props.row.id}`"
                    >
                      <span>View Tracking</span>
                    </b-dropdown-item>
                    <b-dropdown-item :to="`/order-summary/${props.row.id}`">
                      <span>Edit Order</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        cancelOrder(props.row.id, props.row.originalIndex)
                      "
                    >
                      <span>Cancel Order</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                    v-show="props.row.status === 'Active'"
                    @click="assignDriver(props.row.id)"
                  >
                    <span>Assign Driver</span>
                  </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </div>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="generateLabel" hide-footer hide-title centered size="lg">
      <OrderLabel :order="orderLabel" />
    </b-modal>
  </b-container>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import OrderFilter from "./components/OrderFilter.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import exportFromJSON from "export-from-json";
import csvDownload from "json-to-csv-export";
import OrderLabel from "../Label/OrderLabel.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    MyTable,
    flatPickr,
    OrderFilter,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    OrderLabel,
  },

  data() {
    return {
      userData: {},
      orderLabel: {},
      dateFilter: {},
      dummyData: {},
      type: "",
      isLoading: true,
      orderID: null,
      idx: null,
      filter: "Active",
      optionsStatus: {
        "In Warehouse": "In Warehouse",
        "Arrived At Branch": "Arrived At Branch",
        "Out For Delivery": "Out For Delivery",
        Delivered: "Delivered",
        "Close Store": "Close Store",
        "Second Delivery": "Second Delivery",
        "Assigned for Last Mile": "Assigned for Last Mile",
      },
      optionsCancel: {
        "Duplicate Order": "Duplicate Order",
        "Wrong Recipient's Address": "Wrong Recipient's Address",
        "Client Not Ready for Pick Up": "Client Not Ready for Pick Up",
        "Cancel Order": "Cancel Order",
        "IT Testing Order": "IT Testing Order",
      },
      filterLabels: [
        { label: "Active" },
        { label: "Assigned" },
        { label: "Scheduled" },
        { label: "History" },
        { label: "Cancelled" },
        { label: "Refund Request" },
        { label: "Failed Payment" },
      ],
      columns: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Shipment",
          field: "order_type",
          tdClass: "text-center align-middle",
        },
        {
          label: "Franchise Origin",
          field: "pickup",
          tdClass: "text-center align-middle",
        },
        {
          label: "Postcode Destination",
          field: "recipient.address_postcode",
          tdClass: "text-center align-middle",
        },
        {
          label: "Order Date",
          field: "order_placed_date",
          tdClass: "text-center align-middle",
        },
        {
          label: "Delivery Date",
          field: "delivery_date",
          tdClass: "text-center align-middle",
        },
        {
          label: "Qty",
          field: "quantity",
          tdClass: "text-center align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
      required,
    };
  },

  async created() {
    if (this.$route.query.date) {
      this.dateFilter = this.$route.query.date;
      this.filter = this.$route.query.filter;
    } else {
      this.dateFilter = new Date().toJSON().slice(0, 10);
    }
    this.getOrders();
  },

  methods: {
    getOrders() {
      this.isLoading = true;
      this.$http
        .get(
          `get_orders_by_type?order_type=Franchise&filter=Active&delivery_date=${this.dateFilter}`
        )
        .then((response) => {
          if (response.data.data?.length > 0) {
            this.rows = [...response.data.data];
          } else {
            this.rows = [];
          }
          this.isLoading = false;
        });
    },

    payOrder(order) {
      this.$router.push({
        name: "mode-of-payment",
        params: {
          data: order,
        },
      });
      // var baseURL = window.location.origin;
      // var newPath = `/Franchise-order/ordersummary?payment_id=${order.id}`;
      // this.userData = getUserData();
      // this.$http
      //   .get(
      //     `pay_order?amount=${order.amount}&order_id=${order.id}&return_url=${baseURL}${newPath}&name=${this.userData.name}&phone=${this.userData.phone}&email=${this.userData.email}&detail=MiniDC Order Payment`
      //   )
      //   .then((response) => {
      //     if (response.data.status) {
      //       if (order.payment_method == "LOGPOINT") {
      //         this.$router.push({
      //           name: "Franchise-ordersummary",
      //           params: { data: order.id },
      //         });
      //       } else {
      //         window.location.href = response.data.url;
      //       }
      //     } else {
      //       if (response.data.errors) {
      //         var errors = response.data.errors;
      //         for (var a in errors) {
      //           var error = errors[a];
      //           this.$toast({
      //             component: ToastificationContent,
      //             props: {
      //               title: error,
      //               icon: "XIcon",
      //               variant: "warning",
      //             },
      //           });
      //         }
      //       } else {
      //         this.$toast({
      //           component: ToastificationContent,
      //           props: {
      //             title: response.data.message,
      //             icon: "XIcon",
      //             variant: "warning",
      //           },
      //         });
      //       }
      //       this.isSubmitting = false;
      //       this.isDisabled = false;
      //     }
      //   });
    },

    generateLabel(order) {
      this.orderLabel = order;
      this.$refs["generateLabel"].show();
    },

    goToOrderSummary(id) {
      this.$router.push({
        name: "order-summary",
        params: {
          id: id,
        },
      });
    },

    async ExportToExcel() {
      const response = await this.$http.get(
        `orders_export/Franchise/${this.dateFilter}/${this.filter}`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `${this.filter}FranchiseOrder_${this.dateFilter}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    updateOrderStatus(id, index) {
      const reasons = this.optionsStatus;
      this.$swal({
        title: "Update Status",
        input: "select",
        inputOptions: reasons,
        inputPlaceholder: "Select Status",
        inputAttributes: {
          "aria-label": "Select Status",
        },
        inputValidator: (value) => !value && "Please give a value",
        allowOutsideClick: true,
        confirmButtonText: "SUBMIT",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          var updateStatus = {};
          updateStatus.order_id = id;
          updateStatus.status = result.value;
          this.$http
            .post(`update_order_status`, updateStatus)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.status == true) {
                  this.$swal({
                    title: "Status Delivery Updated !",
                    text: response.data.message,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.rows[index].status = updateStatus.status;
                } else {
                  this.$swal({
                    title: "Status Delivery Error !",
                    text: response.data.message,
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
              }
            });
        }
      });
    },

    cancelOrder(id, index) {
      const reasons = this.optionsCancel;
      this.$swal({
        title: "Cancel Order",
        input: "select",
        inputOptions: reasons,
        inputPlaceholder: "Select Reason",
        inputAttributes: {
          "aria-label": "Select Reason",
        },
        inputValidator: (value) => !value && "Please give a value",
        allowOutsideClick: true,
        confirmButtonText: "SUBMIT",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          var orderCancel = {};
          orderCancel.order_id = id;
          orderCancel.status = "Cancelled";
          orderCancel.cancel_reason = result.value;
          this.$http
            .post(`distributor/update_status`, orderCancel)
            .then((response) => {
              if (response.status == 200) {
                if (response.data.status == true) {
                  this.$swal({
                    title: "Cancellation Accepted !",
                    icon: "success",
                    text: response.data.message,
                    confirmButtonText: "OK",
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                    buttonsStyling: false,
                  });
                  this.rows[index].status = "Cancelled";
                } else {
                  this.$swal({
                    title: "Cancellation Error !",
                    icon: "error",
                    text: response.data.message,
                    confirmButtonText: "Confirm",
                    customClass: {
                      confirmButton: "btn btn-warning",
                    },
                    buttonsStyling: false,
                  });
                }
              }
            });
        }
      });
    },

    DownloadDO(id) {
      window.open(`${this.$baseURL}/generate_golog_label/${id}`, "_blank");
    },

    async switchFilter(filter) {
      this.isLoading = true;
      if (filter) {
        if (filter === "History") {
          filter = "Delivered";
        }
        this.filter = filter;
      }
      this.$router
        .replace({
          path: "/order/Franchise/",
          query: { date: this.dateFilter, filter: this.filter },
        })
        .catch((err) => {});
      if (this.filter == "Failed Payment") {
        await this.$http
          .get(
            `get_orders_by_type?order_type=Franchise&filter=Active&delivery_date=${this.dateFilter}&payment_filter=Payment Declined`
          )
          .then((response) => {
            if (response.status === 200 && response.data.data?.length > 0) {
              this.rows = [...response.data.data];
              for (let x = 0; x < response.data.data?.length; x++) {
                this.rows[x].pickuptime = this.rows[x].pickup_date.substr(
                  11,
                  18
                );
                this.rows[x].deliverytime = this.rows[x].delivery_date.substr(
                  11,
                  18
                );
              }
            } else {
              this.rows = [];
            }
            this.isLoading = false;
          });
      } else {
        await this.$http
          .get(
            `get_orders_by_type?order_type=Franchise&filter=${this.filter}&delivery_date=${this.dateFilter}`
          )
          .then((response) => {
            if (response.status === 200 && response.data.data?.length > 0) {
              this.rows = [...response.data.data];
              for (let x = 0; x < response.data.data?.length; x++) {
                this.rows[x].pickuptime = this.rows[x].pickup_date.substr(
                  11,
                  18
                );
                this.rows[x].deliverytime = this.rows[x].delivery_date.substr(
                  11,
                  18
                );
              }
            } else {
              this.rows = [];
            }
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
